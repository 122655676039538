.results-page {
  margin: calc(var(--spacing-lg) * -1);
  box-sizing: border-box;

  nav.tab-list {
    background-color: var(--color-blue-x-light);
    padding-left: var(--spacing-lg);
    display: flex;

    li {
      font-weight: var(--font-weight-medium);
      border-bottom: 4px solid var(--color-blue-x-light);
      margin-right: var(--spacing);
      margin-top: var(--spacing-sm);
      transition: border-color 0.4s ease-out;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        border-color: var(--color-blue-dark);
      }
    }
  }

  .drag-container {
    position: relative;
    display: block;
    overflow: hidden;
    user-select: none;
    overflow: hidden;

    .controls {
      z-index: 3;
      padding: var(--spacing);
      position: absolute;
      top: 0;
      right: 0;

      button {
        width: auto;
        margin-bottom: var(--spacing-sm);

        i {
          margin: 0;
          font-size: 1.3rem;
        }
      }
    }

    img {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }
}
