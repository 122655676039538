@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.onboarding-modal {
  article {
    background-color: white;

    h1 {
      display: flex;
      align-items: center;
      line-height: 2rem;

      i {
        margin-right: var(--spacing);
      }
    }

    p {
      margin-bottom: var(--spacing-md) !important;

      &.columns {
        columns: 2;
      }
    }
  }

  .img-container {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      max-height: calc(100vh * 0.5);
    }
  }

  .icon-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5rem;
    justify-content: center;
    margin-bottom: var(--spacing-lg);
    margin-top: calc(var(--spacing-md) * -1.2);
    align-items: center;

    div {
      display: inline-flex;
      align-items: center;
      margin-right: var(--spacing-lg);
    }
  }

  .controls {
    position: sticky;
    padding: var(--spacing-md) 0;
    bottom: -50px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-md);
    z-index: 4;
    background-color: white;
    box-shadow: 0px 0px 15px 5px white;

    button {
      width: auto;
    }

    .prev.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .slide {
    background-color: white;
    position: relative;

    animation-name: fade;
    animation-duration: 1s;
    animation-iteration-count: 0.3s;
    animation-timing-function: ease-out;
  }

  .marker-container {
    display: flex;
    align-items: center;

    .marker {
      cursor: pointer;
      margin: 0 var(--spacing-xs);
      width: 10px;
      height: 10px;
      background-color: var(--color-blue-light);
      border-radius: 5px;

      &.active {
        background-color: var(--color-blue-dark);
      }
    }
  }
}
