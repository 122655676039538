.left-nav {
  padding: var(--spacing-md) 0;

  h4 {
    padding-left: var(--spacing-md);
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .system {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;

    .title-bar {
      font-size: 1.1rem;
      font-weight: var(--font-weight-medium);
      padding: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm)
        var(--spacing-md);
      background-color: var(--color-blue-light);
      transition: all 0.3s ease;
      position: relative;

      .toggle {
        position: absolute;
        right: 0.5rem;
        top: 0.7rem;
        transition: transform 0.3s ease-out;
        cursor: pointer;
      }

      .title {
        cursor: pointer;
        color: var(--color-gray-dark);
        position: relative;
        padding-left: var(--spacing-lg);

        i {
          color: inherit;
          position: absolute;
          left: 0;
          top: 0.1rem;
          font-size: 1.4rem;
        }
      }
    }

    &.open {
      .toggle {
        transform: rotate(180deg);
      }
    }

    &.active .title-bar {
      background-color: var(--color-blue-dark);

      .toggle {
        color: white !important;
      }

      > a {
        color: white;
      }
    }

    &.empty {
      .title-bar {
        pointer-events: none;
        background: none;
      }

      ul.templates {
        display: none;
      }

      .toggle {
        display: none;
      }
    }
  }

  ul.templates {
    padding-top: var(--spacing-sm);
    padding-left: 0;

    > li {
      list-style: none;
      // padding-left: calc(var(--spacing-lg) + 1.4rem);
      // padding-right: var(--spacing-md);
      margin: 0;
      padding-bottom: var(--spacing-xs);

      > a,
      ul.configs {
        padding-left: calc(var(--spacing-lg) + 1.4rem);
      }

      &.active {
        > a {
          color: white;
          background-color: var(--color-blue-dark);
          // color: inherit;
        }
      }
    }
  }

  ul.configs {
    margin: 0.25rem;

    > li {
      padding-left: var(--spacing-md);
      list-style: none;
      position: relative;

      a.active {
        font-weight: var(--font-weight-medium);
      }

      &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 1rem;
        height: 50%;
        border-left: 1px solid var(--color-blue-dark);
        border-bottom: 1px solid var(--color-blue-dark);
      }
    }
  }
}
