.config-slide-out {
  @keyframes slideInLeft {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.modal-is-opening dialog > article,
  &.modal-is-closing dialog > article {
    animation-delay: 0.2s;
    animation-name: slideInLeft;
  }

  dialog[open] {
    padding: 0;

    article {
      position: fixed;
      margin: 0;
      top: 0;
      right: 0;
      height: 100%;
      width: 400px;
      padding: var(--spacing-md);
      max-height: 100vh;
    }
  }

  .display-group-container {
    background-color: rgba(177, 208, 222, 0.75);
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid var(--color-gray-medium);
    border-radius: 0.5rem;
  }

  .display-group-label {
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
  }

  .submit-container {
    position: sticky;
    bottom: -1.75rem;
    z-index: 1;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(247, 247, 247);
  }

  .config-name-label {
    font-weight: var(--font-weight-medium);
    padding-bottom: 0.75rem;
    margin-top: -0.75rem;
    overflow-wrap: break-word;
  }
}
