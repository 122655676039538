dialog {
  &[open] {
    .underlay {
      width: 100%;
      height: 100%;
      position: fixed;
    }

    article {
      position: relative;
      max-width: 70%;
      width: 100%;
      padding: var(--spacing-lg) var(--spacing-xl);

      .close {
        width: 2rem;
        height: 2rem;
        opacity: 0.7;
        border: none;
        background-size: auto 2rem;
        position: absolute;
        top: var(--spacing-lg);
        right: var(--spacing-md);
      }
    }
  }
}

.info-container {
  padding-top: 1.5rem;
}