.action-bar {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-md);

  input[type="submit"],
  button,
  a {
    margin: 0;
    margin-left: var(--spacing-sm);
  }
}

input[type="text"],
input[type="number"],
textarea,
select {
  background-color: white;
}

label {
  font-weight: var(--font-weight-medium);
}

textarea {
  min-height: 5rem;
}
