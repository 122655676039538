.action-menu {
  display: flex;
}

.action-button {
  margin: 0.25rem;
  line-height: 0.2em;
}

.logo-container {
  max-width: 250px;
}