.no-margin {
  margin: 0;
}

.flex-space-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-wrap {
  white-space: nowrap;
}

.truncate {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.center {
  text-align: center;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.margin-top-xl {
  margin-top: var(--spacing-xl);
}

.margin-top-lg {
  margin-top: var(--spacing-lg);
}

.margin-top-md {
  margin-top: var(--spacing-md);
}

.margin-top {
  margin-top: var(--spacing);
}

.margin-top-sm {
  margin-top: var(--spacing-sm);
}

.margin-top-xs {
  margin-top: var(--spacing-xs);
}
