.schedules-page {
  .schedule-container {
    &.fullscreen {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: white;
      margin: 0;
      padding: var(--spacing-md);
      z-index: 20;
      overflow: auto;
    }
  }

  .table-container {
    display: block;
    overflow: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: var(--color-gray-medium);
    }

    table {
      th {
        font-size: 0.9rem;
        vertical-align: bottom;
        line-height: 1rem;
      }

      tbody {
        td {
          border: 1px solid var(--color-gray-medium);
        }

        tr {
          background-color: var(--color-blue-x-light);

          &:nth-child(odd) {
            background-color: var(--color-blue-light);
          }
        }
      }

      td.index-col {
        padding: 0;
        border-color: var(--color-gray-dark);
        background-color: var(--color-gray-dark);
        color: var(--color-white);
        text-align: center;
      }
    }
  }

  .add-user-systems-widget {
    border-radius: 2rem;
    background-color: var(--color-blue-light);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);

    form {
      display: flex;
      margin: 0;

      input[type="text"],
      input[type="number"],
      select {
        margin: 0;
        border: none;
        border-radius: none !important;
        padding-left: 0;
        color: var(--color-blue-dark);
        border-bottom: 2px solid var(--color-blue-dark);

        &[name="quantity"] {
          background-color: white;
          border: none;
        }
      }

      .input-container {
        width: 90%;
        background-color: var(--color-blue-x-light);
        padding: var(--spacing-sm) var(--spacing);
        border-radius: 1rem;
      }

      .submit-container {
        display: flex;
        align-items: center;
        padding-left: var(--spacing-md);
      }
    }
  }
}
