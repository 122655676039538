main.landing {
  padding: var(--spacing-lg) var(--spacing-lg);

  ul.components {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    li {
      list-style: none;
      display: flex;
      align-items: center;

      i {
        font-size: 2.3rem;
        margin-right: var(--spacing-md);
      }
    }
  }

  .cta button {
    padding: var(--spacing);
  }
}

.header-one {
  
}

.header-two {
  font-weight: var(--font-weight-medium);
}

.header-three {
  color: #007681;
}