.step-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--footer-height);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;

  .next-container,
  .prev-container {
    display: flex;
    align-items: center;
    width: 20rem;
    padding: var(--spacing);

    a[role="button"],
    button {
      margin: 0;
    }
  }

  .next-container {
    justify-content: flex-end;
  }

  .prev-container {
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    justify-content: flex-start;
  }

  .step-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    a {
      color: var(--color-gray-dark);
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: var(--font-weight-medium);
      margin-right: var(--spacing-md);
      position: relative;

      &:before {
        position: absolute;
        left: 50%;
        top: -10px;
        margin-left: -10px;
        content: "";
        background-color: var(--color-blue-light);
        border: 2px solid white;
        box-shadow: 0px 0px 0px 2px black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: background-color 0.5s ease-in;
      }

      &.active {
        &:before {
          background-color: black;
        }
      }

      .marker {
        width: calc(100% + var(--spacing-md));
        border-top: 3px solid black;
        margin-bottom: var(--spacing);
      }

      &:first-child .marker {
        width: calc(50% + var(--spacing-md));
        margin-left: 50%;
      }

      &:last-child .marker {
        width: 50%;
        margin-right: 50%;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
