:root {
  --font-size: 16px;
  --font-family: "Roboto", sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;

  --spacing: 1rem;
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 3rem;
  --spacing-xl: 6rem;

  --text-color: #222222;
  --text-sub-color: #b4b8ba;
  --color-white: #ffffff;
  --color-gray-light: #f7f7f7;
  --color-gray: #cacdce;
  --color-gray-medium: #787878;
  --color-gray-dark: #092532;
  --color-blue-light: #b1d0de;
  --color-blue-x-light: #eff6fa;
  --color-blue-dark: #0b9ec1;

  --footer-height: 5rem;
}
