.systems-page {
  ul {
    margin: 0;
    padding: 0;
  }

  .system {
    display: block;
    margin-bottom: var(--spacing-md);
  }

  ul.check-list {
    padding-left: var(--spacing-lg);
  }
}
