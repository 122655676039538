header {
  margin-bottom: var(--spacing-lg);
}

header h1,
header h2 {
  margin: 0;
  font-size: 1.8rem;
}

header h2 {
  font-weight: var(--font-weight-light);
}

h3 {
  font-size: 1.5rem;
}

p {
  line-height: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.with-links {
    display: flex;
    justify-content: space-between;
  }

  .links {
    font-size: 1rem;

    a,
    button {
      cursor: pointer;
      margin-right: var(--spacing-md);
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* icons */
i {
  margin-right: var(--spacing-sm);

  &.right {
    margin-right: 0;
    margin-left: var(--spacing-sm);
  }
}

i.large {
  font-size: 2rem;
  margin-right: var(--spacing);
}

ul.styled-ul {
  li {
    list-style: none;
    position: relative;
    padding-left: var(--spacing-md);
    margin-bottom: var(--spacing);
    margin-left: var(--spacing);

    &:before {
      position: absolute;
      top: 0.4rem;
      left: 0;
      content: " ";
      height: 0.6rem;
      width: 0.6rem;
      background-color: var(--color-blue-light);
    }
  }
}

.no-pointer {
  cursor: default !important;
}

ul.check-list {
  padding: 0;

  li.template {
    list-style: none;
    border: 1px solid var(--color-gray);
    padding: calc(var(--spacing-md) * 0.5) var(--spacing-md);

    label {
      font-size: 1.3rem;
      margin: 0;
      position: relative;
      cursor: pointer;

      i,
      .info {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -0.8rem;
        color: var(--color-gray-dark);
      }

      i {
        color: var(--color-gray-medium);
      }

      input[type="checkbox"] {
        border-radius: 2rem;
        height: 32px;
        width: 32px;
        margin-right: calc(var(--spacing) * 0.8);
        border: 2px solid var(--color-blue-light);
      }
    }
  }
}
