.configs-page {
  h2.system-header {
    margin: var(--spacing-md) 0;
  }

  .system article.template {
    background-color: var(--color-blue-x-light);
    border-radius: 0.5rem;
    padding: var(--spacing-md);
    margin: 0;
    margin-bottom: var(--spacing-md);
  }

  .config-container {
    margin: var(--spacing-sm) 0;
  }

  .config {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-sm);

    &:last-of-type {
      margin-bottom: var(--spacing-md);
    }

    .input-container {
      flex-grow: 1;
      position: relative;
      margin-right: var(--spacing-md);

      input[type="text"] {
        margin: 0;

        &::placeholder {
          color: var(--text-sub-color);
        }
      }

      .config-actions {
        display: flex;
        height: 100%;
        align-items: center;
        position: absolute;
        right: var(--spacing-sm);
        top: 0;

        .lock-toggle {
          color: var(--color-blue-dark);
          font-size: 1.6rem;
          cursor: pointer;
          margin-right: var(--spacing);
        }
      }
    }
  }
}
